import { Component, ViewChild, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { ITreeNode, TreeNode, TreeNodeType } from '../../models/tree-node.interface';
import { BackendService } from 'src/app/services/backend.service';
declare var $: any;
@Component({
  selector: 'app-standard-template',
  templateUrl: './standard-template.component.html',
  styleUrls: ['./standard-template.component.scss']
})
export class StandardTemplateComponent implements OnInit, OnDestroy {
  nodes = [];
  private nodeLookup = {};
  editorCounter = 0;
  isAssetInfo =false;
  selectedOption = 'asset';
  name:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public backendService: BackendService,
    public dialogRef: MatDialogRef<StandardTemplateComponent>,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    if(this.data.block){
      this.name =this.data.block.name;
      this.selectedOption =this.data.block.availability;
      this.nodes =this.data.block.nodes;
      this.nodes.forEach((node, index) => {
        if(node.assetType){
          this.isAssetInfo=true;
        }
        this.nodeLookup[node.id] = node;
        setTimeout(() => this.initializeSummernote(node.id, node.data), 20)
      });
    }

  }

  ngOnDestroy() {
  }

  saveContent() {
    const data = {
      nodes: this.nodes,
      domain: this.data.asset.domain,
      projectId: this.data.projectId,
      assetId: this.data.assetId,
      availability: this.selectedOption,
      name:this.name
    }
    if(!this.data.block){
      this.backendService.addDoaminReportBlock(this.data.asset.domain, data).then(() => {
        this.dialogRef.close();
      })
    }
    else{
      this.backendService.editDoaminReportBlock(this.data.asset.domain, data,this.data.block.id).then(() => {
        this.dialogRef.close();
      })
    }
  }

  assetInfo() {
    this.addAssetInfoHeading();
    this.isAssetInfo=true;
    if(this.selectedOption === 'domain'){
      this.selectedOption="asset";
    }
  }

  removeNode(id: string): void {
   if(this.nodeLookup[id].assetType){
     this.isAssetInfo=false;
   }
    delete this.nodeLookup[id];
    this.findAndRemoveNode(id, this.nodes);

  }

  findAndRemoveNode(id: string, list: ITreeNode[]): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        list = list.splice(i, 1);
      } else {
        this.findAndRemoveNode(id, list[i].children);
      }
    }
  }

  addAssetInfoHeading(): string {
    const data = this.assetInformation() + this.companyInformation() + this.contactInformation();
    const node = Object.assign({ title: "Asset being Inspected",assetType:true }, new TreeNode(TreeNodeType.HTML, ''));
    this.nodes.push(node);
    this.nodeLookup[node.id] = node;
    setTimeout(() => this.initializeSummernote(node.id, data), 100)
    return node.id;
  }

  deepDiveInfo() {
    const node = Object.assign({ title: "DeepDIVE – SMART INSPECTIONS" },
      new TreeNode(TreeNodeType.HTML, ''));
    this.nodes.push(node);
    this.nodeLookup[node.id] = node;

    return node.id;
  }

  assetInformation() {
    return `<p><strong>Asset Information</strong></p>
  <table class="table table-bordered">
    <tbody>
        <tr>
            <td>Asset ID: ${this.data.asset.assetId}</td>
            <td>Asset Number: ${this.data.asset.assetNumber}</td>
        </tr>
        <tr>
            <td>Asset Name: ${this.data.asset.assetName}</td>
            <td>Country: ${this.data.asset.country}</td>
        </tr>
        <tr>
            <td>Industry: ${this.data.asset.industry}</td>
            <td>Date Of Comm: ${this.data.asset.dateOfComm}</td>
        </tr>
        <tr>
            <td>Eq Class: ${this.data.asset.eqClass}</td>
            <td>Eq Sub Class: ${this.data.asset.eqSubClass}</td>
        </tr>
        <tr>
            <td>Latitude: ${this.data.asset.latitude}</td>
            <td>Longitude: ${this.data.asset.longitude}</td>
        </tr>
        <tr>
            <td>Location: ${this.data.asset.location}</td>
            <td></td>
        </tr>
    </tbody>
</table>`
  }

  new() {
    const node = Object.assign({ title: "Title" }, new TreeNode(TreeNodeType.HTML, ''));
    this.nodes.push(node);
    this.nodeLookup[node.id] = node;
    setTimeout(() => this.initializeSummernote(node.id, ''), 100)
    return node.id;

  }

  companyInformation() {
    return `
  <p><strong>Company Information</strong></p>
  <table class="table table-bordered">
  <tbody>
      <tr>
          <td>Company Name: ${this.data.asset.companyName}</td>
          <td>Address: ${this.data.asset.address}</td>
      </tr>
      <tr>
          <td>City: ${this.data.asset.city}</td>
          <td>State: ${this.data.asset.state}</td>
      </tr>
      <tr>
          <td>Country: ${this.data.asset.country}</td>
          <td>Zip: ${this.data.asset.zip}</td>
      </tr>
      <tr>
          <td>Phone: ${this.data.asset.phone}</td>
          <td>Fax: ${this.data.asset.fax}</td>
      </tr>
  </tbody>
</table>`
  }

  contactInformation() {
    return `<p><strong>Contact Information</strong></p>
  <table class="table table-bordered">
  <tbody>
  <tr>
      <td>Contact Name: ${this.data.asset.contactName}</td>
      <td>Email: ${this.data.asset.email}</td>
  </tr>
  <tr>
      <td>Mobile: ${this.data.asset.mobileContact}</td>
      <td>Work Contact: ${this.data.asset.workContact}</td>
  </tr>
  <tr>
      <td>Location: ${this.data.asset.contactPersonLocation}</td>
      <td>Department: ${this.data.asset.department}</td>
  </tr>
</tbody>
</table>`
  }
  // Initialize Summernote for the specified editor
  initializeSummernote(editorId, content) {
    const editorElement = $(`#editor${editorId}`);
    editorElement.summernote({
      height: 300,
      focus: true,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript','fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'table']],
        ['view', ['fullscreen', 'codeview']]
      ],
      fontNames: ['Arial', 'Courier New', 'Times New Roman', 'Verdana', 'Sans-serif'], // Define custom fonts
      callbacks: {
        onChange: (contents: any) => {
          this.nodeLookup[editorId].data = contents; // Update the content in the editors array
        }
      }
    });

    // Set initial value for existing content
    editorElement.summernote('code', content);
  }

 

}
